@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Oxygen:wght@300;400;700&display=swap');

/* ::-webkit-scrollbar {
  border-radius: 20;
  background: inherit;
}

::-webkit-scrollbar-track {
  background: #bec2ce;
  border-radius: 20px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c4a6c5;
  border-radius: 100vh;
  width: 1px;
} */

html,body
{
    width: 100%;
    height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden; 
    font-family: 'Oxygen', sans-serif;
}

.titleCardFade {
  transition-timing-function: ease-in;
  transition: 0.5s;
}

/* .class {

  color: rgb(201, 0, 124)
} */